import Head from "next/head";

export default function Home() {
	return (
		<div className="p-8 font-medium">
			<Head>
				<title>C#onepager</title>
				<meta name="description" content="Generated by create next app" />
				<link rel="icon" href="/favicon.ico" />
			</Head>

			<h3 className="text-2xl">Onepager page</h3>
		</div>
	);
}
